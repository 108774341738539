/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Inject, Injectable } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ConfigService } from '../services/config/config.service'
import { WINDOW } from '../services/utils'
import {NewRelicService} from "../services/new-relic/new-relic-service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(WINDOW) private readonly _window: Window,
    private readonly _configService: ConfigService,
    private readonly _router: Router,
    private readonly newRelicService: NewRelicService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const traceId = req.headers?.get('X-TRACE-ID') || ''
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // eg. add vehicle requires the user to be logged in, let the transaction guard handle the redirect
        if (error?.status === 401 && error?.error?.errorCode === 'UNAUTHORIZED') {
          return throwError(() => error)
        }

        // Forward the error to New Relic for monitoring
        this.newRelicService.noticeError(error, traceId)

        // Don't redirect to the error page, handle error in the component where it has been thrown
        if (
          [
            'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_INVALID',
            'BUSINESS_EXCEPTION_TRANSACTION_FAILED_CODE_VERIFICATION',
            'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_TEMPORARILY_BLOCKED',
            'BUSINESS_EXCEPTION_CONFIRMED_PORSCHE_ID_ALREADY_EXISTS',
            'BUSINESS_EXCEPTION_UNSUPPORTED_MOBILE_NUMBER',
            'TOO_MANY_REQUESTS_OTP',
            'RESOURCE_NOT_FOUND_SHORTLINK',
            'RESOURCE_NOT_FOUND_TRANSACTION',
            'RESOURCE_NOT_FOUND_OPEN_INVITATIONS',
          ].includes(error.error.errorCode)
        ) {
          return throwError(() => error)
        }

        if(req.url.includes('pictures')) {
          return throwError(() => error)
        }

        if (req.url.includes('organisation')) {
          return throwError(() => error)
        }

        if (error?.status === 502 && req.url.includes('spin')) {
          return throwError(() => error)
        }

        // Add error key as state to pass it on to the error component and replace the current url to prevent the user from going back in history
        // but send the requested url to the state for analytics but obfuscated
        const reqUrl = req.url
          .replace(/[0-9]/g, '*')
          .replace(/[a-z]+\*/g, '*'.repeat('$&'.length))
          .replace(/\*[a-z]+/g, '*'.repeat('$&'.length))
        this._router.navigate([this._configService.getUrlCountry(), this._configService.getUrlLocale(), 'error'], {
          state: { errorKey: error?.error?.errorCode, errorOnUrl: reqUrl, statusCode: error?.status },
          replaceUrl: true,
        })
        return throwError(() => error)
      }),
    )
  }
}

export const HttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
}
